import { ISdkChatOptions, ISdkIframePostMessage } from "../libs";

export const postMessageToChild = (
  iframe: HTMLIFrameElement,
  message: ISdkIframePostMessage
) => {
  if (!iframe.contentWindow) return console.error("iframe not found");
  // wait for 100 for safari
  setTimeout(() => {
    iframe.contentWindow!.postMessage(message, "*");
  }, 100);
};

export const getInititalMessage = (
  options: ISdkChatOptions
): ISdkIframePostMessage => {
  const { bubble, ...rest } = options;
  return {
    sender: "ams_sdk",
    topic: "init",
    data: {
      ...rest,
      channelId: options.channelId,
      mode: options.mode,
    },
  };
};

export const getReinitMessage = (
  token: string,
  options: ISdkChatOptions
): ISdkIframePostMessage => {
  const { bubble, ...rest } = options;
  return {
    sender: "ams_sdk",
    topic: "re-init",
    data: {
      ...rest,
      token,
      channelId: options.channelId,
      mode: options.mode,
    },
  };
};
