const getEndpoint = () => {
  const url = import.meta.env.VITE_WEBCHAT_ENDPOINT;
  if (url) {
    return url;
  }

  return "http://localhost:3000";
};

export const createIframe = () => {
  const webchatEndpoint = getEndpoint();
  if (!webchatEndpoint) {
    console.error("webchat endpoint not found");
    return;
  }
  const iframe = document.createElement("iframe");
  // Set the source URL of the iframe
  iframe.src = webchatEndpoint;
  // Set the width and height of the iframe
  iframe.width = "100%";
  iframe.height = "100%";
  // Set any other attributes for the iframe element if needed
  iframe.setAttribute("frameborder", "0");
  iframe.setAttribute("scrolling", "no");
  iframe.setAttribute("marginheight", "0");
  iframe.setAttribute("marginwidth", "0");
  iframe.setAttribute("allowfullscreen", "true");
  iframe.setAttribute("name", "ams_sdk_frame");
  iframe.setAttribute(
    "allow",
    "geolocation *; microphone; camera; midi; encrypted-media;"
  );
  return iframe;
};
