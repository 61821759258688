import { isEmpty } from "lodash";
import XmarkIcon from "../assets/icons/xmark-solid.svg";
import {
  BUTTON_IMAGE_STYLE,
  BUTTON_STYLE,
  DEFAULT_OPTION,
  FRAME_CONTAINER_STYLE,
  FRAME_CONTENT_HEADER_STYLE,
  FRAME_CONTENT_STYLE,
} from "../constants";
import { EToggleStatus } from "../enums/bubble";
import { ISdkBubbleOption } from "../libs";

export class Bubble {
  options: ISdkBubbleOption | null = null;
  button: {
    container: HTMLButtonElement;
    image: HTMLImageElement;
  } | null;
  frame: {
    container: HTMLDivElement | null;
    header: HTMLDivElement | null;
    body: HTMLDivElement | null;
  } | null;

  constructor(options?: ISdkBubbleOption) {
    this.options = options ? options : null;
    this.frame = null;
    this.button = null;
    this.initButton();
  }

  get frameObj() {
    return this.frame;
  }

  get buttonObj() {
    return this.button;
  }

  private initButton() {
    const buttonStyle = this.options?.button?.style;
    const buttonImage = this.options?.button?.image;
    const container = this.buildButton(
      "ams_sdk-button-container",
      buttonStyle ? `${BUTTON_STYLE}; ${buttonStyle}` : BUTTON_STYLE
    );
    const buttonImageEl = this.buildImage(
      "ams_sdk-button-image",
      buttonImage?.style
        ? `${BUTTON_IMAGE_STYLE}; ${buttonImage.style}`
        : BUTTON_IMAGE_STYLE,
      buttonImage?.url ? buttonImage.url : DEFAULT_OPTION.BUTTON.IMAGE_URL
    );

    container.appendChild(buttonImageEl);
    container.onclick = () => this.handleButtonClick();
    this.button = {
      container,
      image: buttonImageEl,
    };

    this.appendElement(this.button.container);
  }

  private handleButtonClick() {
    if (this.button) {
      // first time
      if (!this.frame?.container) {
        this.initFrame();
        this.button.container.style.display = "none";
        this.options?.onToggle(EToggleStatus.OPEN);
        return;
      }
      // show
      this.button.container.style.display = "none";
      this.frame.container.style.display = "flex";
      this.frame.container.style.bottom = "18px";
      this.options?.onToggle(EToggleStatus.OPEN);
    }
  }

  private handleCloseButtonClick() {
    if (this.button && this.frame?.container) {
      this.button.container.style.display = "flex";
      this.frame.container.style.bottom = "-100%";
    }
  }

  private initFrame() {
    const width = this.options?.frameContainer?.width
      ? this.options?.frameContainer?.width
      : DEFAULT_OPTION.FRAME_WIDTH;
    const height = this.options?.frameContainer?.height
      ? this.options?.frameContainer?.height
      : DEFAULT_OPTION.FRAME_HEIGHT;
    const frameContainerStyle = this.options?.frameContainer?.style ?? "";
    const frame = this.buildDiv(
      "ams_sdk-frame-container",
      `
        ${FRAME_CONTAINER_STYLE}
        ${frameContainerStyle};
        height: ${height};
        width: ${width};
     `
    );
    // delay animation
    setTimeout(() => {
      frame.style.bottom = "18px";
    }, 1);

    const content = this.buildDiv("ams_sdk-frame-content", FRAME_CONTENT_STYLE);

    const frameHeader = this.options?.frameContainer?.header;
    const frameHeaderStyle = frameHeader?.style ?? "";
    const contentHeader = this.buildDiv(
      "ams_sdk-frame-content-header",
      `
        ${FRAME_CONTENT_HEADER_STYLE}
        background: ${DEFAULT_OPTION.PRIMARY_COLOR};
        ${frameHeaderStyle}
      `
    );

    const frameHeaderTitle = frameHeader?.title;
    const headerTitle = this.buildText(
      "h4",
      `
      font-size: 16px;
      color: white;
      font-weight: bold;
      margin: 0;
      ${frameHeaderTitle?.style ?? ""}
      `,
      frameHeaderTitle?.text ?? DEFAULT_OPTION.FRAME_CONTAINER.TITLE
    );
    const customCloseBtn = this.options?.frameContainer?.header?.customCloseBtn;
    const defaultStyle = `position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          width: 15px;
          height: 15px;
          color: white;`;
    let headerCloseButton;
    if (!isEmpty(customCloseBtn)) {
      headerCloseButton = this.buildImage(
        "ams_sdk-frame-content-header-close-button",
        customCloseBtn.style
          ? `${defaultStyle} ${customCloseBtn.style}`
          : defaultStyle,
        customCloseBtn.url
      );
    } else {
      headerCloseButton = this.buildImage(
        "ams_sdk-frame-content-header-close-button",
        `
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          width: 15px;
          height: 15px;
          color: white;
        `,
        XmarkIcon
      );
    }

    headerCloseButton.onclick = () => this.handleCloseButtonClick();

    const contentBody = this.buildDiv(
      "ams_sdk-frame-content-body",
      `
        flex: 1;
        height: 100%;
      `
    );
    // append title to header
    contentHeader.appendChild(headerTitle);
    // append close button to header
    contentHeader.appendChild(headerCloseButton);
    // append header to content
    content.appendChild(contentHeader);
    // append body to content
    content.appendChild(contentBody);
    // append content to frame
    frame.appendChild(content);
    // add frame to class
    this.frame = {
      container: frame,
      header: contentHeader,
      body: contentBody,
    };
    // append frame to body
    if (!this.frame.container) throw new Error("frame container is null");
    this.appendElement(this.frame.container);
  }

  private buildButton(id: string, cssText: string) {
    const button = document.createElement("button");
    button.id = id;
    button.style.cssText = cssText;
    return button;
  }
  private buildImage(id: string, cssText: string, url: string) {
    const img = document.createElement("img");
    img.id = id;
    img.style.cssText = cssText;
    img.src = url;

    return img;
  }

  private buildDiv(id: string, cssText: string) {
    const div = document.createElement("div");
    div.id = id;
    div.style.cssText = cssText;
    return div;
  }

  private buildText(el: string, cssText: string, text: string) {
    const title = document.createElement(el);
    title.style.cssText = cssText;
    title.textContent = text;

    return title;
  }

  private appendElement(element: HTMLElement) {
    document.body.appendChild(element);
  }
}
