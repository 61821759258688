export const DEFAULT_OPTION = {
  PRIMARY_COLOR: "#1dc497",
  FRAME_CONTAINER: {
    TITLE: "Amity Solutions",
  },
  BUTTON: {
    IMAGE_URL:
      "https://upload.convolab.ai/convolab/images/convolab-chaticon-2.svg",
  },
  FRAME_HEIGHT: "80%",
  FRAME_WIDTH: "370px",
};

export const BUTTON_STYLE = `
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  z-index: 1001;
  position: fixed;
  bottom: 18px;
  right: 18px;
  width: 60px;
  height: 60px;
  background: ${DEFAULT_OPTION.PRIMARY_COLOR}
`;

export const BUTTON_IMAGE_STYLE = `
  width: 40px;
  height: 40px;
`;

export const FRAME_CONTAINER_STYLE = `
  position: fixed;
  bottom: -100%;
  right: 18px;
  z-index: 1002;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.15) 0 3pt 12px;
  background: white;
  display: flex;
  overflow: hidden;
  transition: all ease-in-out 0.3s;
`;

export const FRAME_CONTENT_STYLE = `
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const FRAME_CONTENT_HEADER_STYLE = `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
`;
