import { Bubble } from "./fns/bubble";
import { createElement } from "./fns/createElement";
import { createIframe } from "./fns/createIframe";
import {
  getInititalMessage,
  postMessageToChild,
  getReinitMessage,
} from "./fns/iframeMessage";
import { ISdkChatOptions } from "./libs";
(function () {
  window.ams_sdk = {
    chat: {
      load: (options) => {
        setupIframe(options);
        return (token: string, newOptions: ISdkChatOptions) => {
          reloadContent(token, { ...options, ...newOptions });
        };
      },
    },
  };
  const setupIframe = (options: ISdkChatOptions) => {
    if (options.mode === "bubble") {
      setupBubble(options);
    }
    // full_screen
    else {
      const iframe = setupFullPage();
      if (!iframe) return console.error("iframe not found");
      iframe.onload = () => {
        postMessageToChild(iframe, getInititalMessage(options));
      };

      iframe.onerror = (error) => {
        console.error(error);
      };
    }
  };

  function setupBubble(options: ISdkChatOptions) {
    if (!options.channelId) return;
    let isIframeinit = false;
    const bubble = new Bubble({
      ...options.bubble,
      onToggle: () => {
        if (isIframeinit) return;
        // create iframe
        const iframe = createIframe();
        if (!iframe) return console.error("iframe not found");
        // Append the iframe element to the iframe container
        if (bubble.frameObj && bubble.frameObj.body) {
          isIframeinit = true;
          bubble.frameObj.body.appendChild(iframe);
          iframe.onload = () => {
            postMessageToChild(iframe, getInititalMessage(options));
          };

          iframe.onerror = (error) => {
            console.error(error);
          };
        }
      },
    });

    return "";
  }

  const setupFullPage = () => {
    const frameContainer = createElement("div", "ams_sdk-frame-container");
    document.body.appendChild(frameContainer);
    // set the container to full page
    frameContainer.style.position = "fixed";
    frameContainer.style.top = "0";
    frameContainer.style.left = "0";
    frameContainer.style.width = "100%";
    frameContainer.style.height = "100%";
    const iframe = createIframe();
    if (!iframe) return;
    // Append the iframe element to the HTML document
    frameContainer.appendChild(iframe);
    return iframe;
  };

  const reloadContent = (token: string, options: ISdkChatOptions) => {
    const iframe = document.querySelector("iframe");
    if (!iframe) return console.error("iframe not found");

    postMessageToChild(iframe, getReinitMessage(token, options));
  };
})();
